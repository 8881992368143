import React from 'react';
import {
    Content,
    ScreenshotContainer,
    ScreenshotImage,
    TextContainer,
    Title,
    SubTitle,
    Description,
    StoreBadge
} from './styles';
import { useTheme } from '../../hooks/useTheme';
import { appStoreURL } from '../../constants/urls';

const Home = () => {
    const isDarkMode = useTheme() === 'dark';

    return (
        <Content>
            <TextContainer>
                <Title>Snap it, Know it.</Title>
                <SubTitle>Instantly recognize and explore the world around you with detailed insights, fun facts, and useful links.</SubTitle>
                <Description>Discover the world through your lens with RecognAIzer! Just snap a photo, and our AI will identify the main object, providing you with detailed descriptions, fun facts, and useful links. Whether you want to learn more about a unique item, explore purchase options, or dive deeper with Wikipedia, RecognAIzer has you covered. Easy to use, no sign-up required—download now and start recognizing everything around you!</Description>
                <a href={appStoreURL}><StoreBadge src="assets/Download_on_the_App_Store_Badge.svg" /></a>
            </TextContainer>
            <ScreenshotContainer><ScreenshotImage src={`/assets/screenshot${isDarkMode ? '_dark' : ''}.png`} /></ScreenshotContainer>
        </Content>
    )
}

export default Home;

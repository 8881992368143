import { useTheme } from '../../hooks/useTheme';
import { Container, HeaderLogo, HeaderLogoContainer, StoreBadge, StoreBadgeContainer } from './styles';
import { appStoreURL } from '../../constants/urls';

const Header = () => {
    const isDarkMode = useTheme() === 'dark';

    return (
        <Container>
            <HeaderLogoContainer href="/"><HeaderLogo src={`/assets/banner${isDarkMode ? '_dark' : ''}.png`} /></HeaderLogoContainer>
            <StoreBadgeContainer href={appStoreURL}><StoreBadge src="/assets/Download_on_the_App_Store_Badge.svg" /></StoreBadgeContainer>
        </Container>
    );
}

export default Header;

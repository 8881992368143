import { Link } from "react-router-dom";
import { Container, FooterContent, FooterLink } from './styles';

const Footer = () => {
    return (
        <Container>
            <FooterContent>
                <FooterLink><Link to="/privacy">Privacy Policy</Link></FooterLink>
                <FooterLink><Link to="/terms">Terms</Link></FooterLink>
                <FooterLink><a href="mailto:contact@aarstudio.com">Contact</a></FooterLink>
            </FooterContent>
        </Container>
    );
}

export default Footer;

import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    height: 75px;
    width: 100%;
    border-bottom: 1px solid var(--border);
    align-items: center;
    display: flex;
    justify-content: center;
    background: var(--background);
    display: flex;
    align-items: center;
    
    
    @media (min-width: 600px) {
        justify-content: space-between;
    }
    
    @media (max-width: 599px) {
        justify-content: space-evenly;
    }
`;

export const HeaderLogoContainer = styled.a`
    height: 100%;
    margin: auto;
    flex-shrink: 0;
    transition: margin 0.3s ease;

    @media (min-width: 600px) {
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 599px) {
        margin-left: unset;
        margin-right: unset;
        height: 72%;
    }
`;

export const HeaderLogo = styled.img`
    height: 100%;
`;

export const StoreBadgeContainer = styled.a`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    flex-shrink: 0;
`;

export const StoreBadge = styled.img`
    height: 50%;
`;
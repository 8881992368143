import styled from 'styled-components';

const footerHeight = 60;
export const Container = styled.div`
    border-top: 1px solid var(--border);
    height: ${footerHeight}px;
    width: 100%;
`;

export const FooterContent = styled.div`
    height: 100%;
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-column-gap: 20px;
    margin: auto;
`;

export const FooterLink = styled.span`
    a {
        color: var(--text-link);
        font-size: 0.8em;
        text-decoration: none;
        line-height: ${footerHeight}px;

        &:hover {
            text-decoration: underline;
        }
    }
`;

import { useEffect, useState } from "react";

/**
 * Hook to get the theme from the system
 * @returns 'light' | 'dark'
 */
export const useTheme = () => {
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [theme, setTheme] = useState<'light' | 'dark'>(isDarkMode ? 'dark' : 'light');

    useEffect(() => {
        const listener = (e: MediaQueryListEvent) => setTheme(e.matches ? 'dark' : 'light');
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', listener);

        setTheme(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', listener);
        }
    }, []);

    return theme;
}
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from '../Home';
import Subject from '../Subject';
import { Container } from './styles';
import Header from '../Header';
import Footer from '../Footer';
import Markdown from '../Markdown';
import privacySource from '../../assets/privacy.md';
import termsSource from '../../assets/terms.md';
import { useTheme } from '../../hooks/useTheme';

const App = () => {
    const theme = useTheme();

    useEffect(() => {
        document.body.setAttribute('data-theme', theme);
    }, [theme]);

    return (
        <Router>
            <Container>
                <Header />

                {/* 135px = height of header (75px) + height of footer (60px) */}
                <div style={{ minHeight: 'calc(100vh - 135px)' }}>
                    <Routes>
                        <Route path="/privacy" element={<Markdown source={privacySource} />} />
                        <Route path="/terms" element={<Markdown source={termsSource} />} />
                        <Route path="s/:id" element={<Subject />} />
                        <Route path="/" element={<Home />} />

                    </Routes>
                </div>

                <Footer />
            </Container>
        </Router>
    );
}

export default App;

import styled from "styled-components";

export const LinkA = styled.a`
    color: var(--text-link);
    font-size: 0.8em;
    text-decoration: none;
    margin-right: 0px;
    padding: 10px;
    display: grid;
    grid-gap: 5px;
`;

export const LinkImage = styled.img`
    background: var(--background-secondary);
    border-radius: 11px;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    box-shadow: var(--shadow);
    vertical-align: middle;
    padding: 10px;
    justify-self: center;

    &:hover {
        background: var(--background-hover);
    }
`;

export const LinkText = styled.div`
    vertical-align: middle;
    justify-self: center;
    white-space: pre-line;
    text-align: center;
`;
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Content } from './styles';

interface MarkdownProps {
  source: string;
}

const Markdown: React.FC<MarkdownProps> = ({ source }) => {
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    fetch(source)
      .then(res => res.text())
      .then((text) => setContent(text));
  }, [source]);

  return (
    <Content>
      <ReactMarkdown>{content}</ReactMarkdown>
    </Content>
  );
};

export default Markdown;

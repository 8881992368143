import styled from 'styled-components';

export const Content = styled.div`
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 40px;

    h1 {
        text-align: center;
        margin-bottom: 40px;
        font-size: 3em;
        color: var(--text-title);
    }

    h2 {
        margin-top: 40px;
        font-size: 2em;
        color: var(--text-title);
    }

    p {
    }
    
    a {
        color: var(--text-link);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;
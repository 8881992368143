import { LinkA, LinkImage, LinkText } from './styles';
import { Link } from '../../../types/subject';
import { useTheme } from '../../../hooks/useTheme';

interface LinkViewProps {
    link: Link;
}

const LinkView: React.FC<LinkViewProps> = ({ link }) => {
    const isDarkMode = useTheme() === 'dark';

    const replacements = {
        "google.com": { name: "Search on\nGoogle", icon: "/assets/google_icon.png", iconDark: null },
        "amazon.com": { name: "Buy on\nAmazon", icon: "/assets/amazon_icon.png", iconDark: "/assets/amazon_icon_dark.png" },
        "wikipedia.org": { name: "Learn on\nWikipedia", icon: "/assets/wikipedia_icon.png", iconDark: "/assets/wikipedia_icon_dark.png" },
    }

    const getLinkName = () => {
        for (const [key, value] of Object.entries(replacements)) {
            if (link.url.toLowerCase().includes(key.toLowerCase())) {
                return value.name;
            }
        }

        return link.name;
    };

    const getLinkIcon = () => {
        for (const [key, value] of Object.entries(replacements)) {
            if (link.url.toLowerCase().includes(key.toLowerCase())) {
                if (isDarkMode && value.iconDark) {
                    return value.iconDark;
                }

                return value.icon
            }
        }

        return link.icon;
    };

    return (
        <LinkA target="_blank" href={link.url}>
            <LinkImage src={getLinkIcon()} />
            <LinkText>{getLinkName()}</LinkText>
        </LinkA>
    );
}

export default LinkView;
